import { useMemo } from "react";
import { useRouter } from "next/router";
import { useAPIAllImmutable } from "./useAPIAllImmutable";

interface ILanguageInfo {
  cultureName?: string | undefined;
  uiCultureName?: string | undefined;
  displayName?: string | undefined;
  flagIcon?: string | undefined;
}

/**
 * A hook that returns an array of available locales.
 * Intersects the data returned from "/api/app/available-languages" API endpoint
 * and the locales configured in next.config.js.
 * @returns An array of available locales.
 */
export function useAvailableLocales(): string[] {
  const { data } = useAPIAllImmutable<ILanguageInfo[]>(
    "/api/app/available-languages"
  );
  const { locales } = useRouter();
  return useMemo(() => {
    if (!data || !locales) {
      return [];
    }
    return data
      .filter(
        (language): language is WithRequired<ILanguageInfo, "cultureName"> =>
          Boolean(
            language.cultureName && locales.includes(language.cultureName)
          )
      )
      .map((language) => language.cultureName);
  }, [locales, data]);
}
