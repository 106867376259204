import Stack from "@mui/material/Stack";
import { BrandLogo } from "../BrandLogo";
import { Link } from "../Link";
import { NavSections } from "./NavSections";

export function Nav() {
  return (
    <Stack
      component="nav"
      direction="row"
      alignItems="center"
      px={6}
      spacing={3}
      bgcolor="background.paper"
      color="primary.dark"
      maxWidth="100vw"
    >
      <Link sx={{ pt: 1, maxWidth: "200px" }} href="/" passHref>
        <BrandLogo />
      </Link>
      <NavSections />
    </Stack>
  );
}
