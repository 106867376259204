import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SUPPORT_EMAIL } from "config";

export function ApplicationConfigurationErrorPage({
  message,
}: {
  message: string;
}) {
  return (
    <Container maxWidth="lg" sx={{ height: "100vh" }}>
      <Stack height="100%" gap={16} alignItems="center" justifyContent="center">
        <Box>
          <Typography
            textAlign="center"
            variant="h3"
            marginBottom={2}
            sx={{ overflowWrap: "anywhere" }}
          >
            {message}
          </Typography>
          <Typography textAlign="center" variant="h5">
            The error has been logged. Please refresh the page, or contact{" "}
            <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
          </Typography>
        </Box>
      </Stack>
    </Container>
  );
}
