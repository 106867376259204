import { Children, Fragment, ReactNode } from "react";
import { uniqueKeyGetter } from "core/utils/uniqueKeyGetter";
import Divider from "@mui/material/Divider";

export function JoinChildren({
  children,
  JoinElement = <Divider sx={{ my: 2 }} />,
}: {
  children: ReactNode;
  JoinElement?: ReactNode;
}) {
  const arrayed = Children.toArray(children);
  const getUniqueKey = uniqueKeyGetter();

  return (
    <>
      {arrayed.map((child, index, { length }) => {
        const isLast = index === length - 1;
        if (typeof child === "object" && "key" in child && child.key !== null) {
          const key = getUniqueKey(child.key);
          return isLast ? (
            <Fragment key={key}>{child}</Fragment>
          ) : (
            <Fragment key={key}>
              {child}
              {JoinElement}
            </Fragment>
          );
        }
        return isLast ? (
          child
        ) : (
          <Fragment key={getUniqueKey(child.toString())}>
            {child}
            {JoinElement}
          </Fragment>
        );
      })}
    </>
  );
}
