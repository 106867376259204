import { APIGetAll } from "core/utils/APIGetAll";
import { APIError, ReqPath } from "core/entities";
import useSWR from "swr";
import { useAPIHeaders } from "./useAPIHeaders";

export function useAPIAllImmutable<T>(path: ReqPath) {
  const headers = useAPIHeaders();
  // https://swr.vercel.app/docs/conditional-fetching
  const key = path ? [path, headers] : null;
  const { data, error, mutate } = useSWR<T, APIError>(key, APIGetAll, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
