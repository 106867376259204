import { useRef, useEffect } from "react";
import { useDebouncedCallback } from "./useDebouncedCallback";

export function usePostScrollHeightToParent() {
  const postMessageHeight = useDebouncedCallback(
    () => {
      // If we're not in an iframe, don't do anything.
      if (!window.parent || window.parent === window) {
        return;
      }
      const { scrollHeight } = document.body;
      window.parent.postMessage(
        {
          type: "scrollHeight",
          scrollHeight,
        },
        "*"
      );
    },
    [],
    100
  );
  const observerRef = useRef<ResizeObserver | null>(null);
  useEffect(() => {
    if (observerRef.current === null) {
      observerRef.current = new ResizeObserver(postMessageHeight);
    }
    const observer = observerRef.current;
    if (observer) {
      observer.observe(document.body);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [postMessageHeight]);
}
