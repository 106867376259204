import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowLeft from "@mui/icons-material/KeyboardDoubleArrowLeft";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { useCanManage } from "core/hooks/useCanManage";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { MobileCommunityFeedback } from "features/Notifications/CommunityFeedback";
import { MobileMyLinksListItem } from "features/MyLinks/components/MobileMyLinksListItem";
import { Link } from "../Link";
import { MobileNavMenuItems } from "./MobileNavMenuItems";
import { MobileNavSearch } from "./MobileNavSearch";

interface MobileNavProps {
  open: boolean;
  handleClose: () => void;
}

export function MobileNav({ open, handleClose }: MobileNavProps) {
  const { t } = useTranslation("Fixhub", { keyPrefix: "Next:Core:MobileNav" });
  const canManage = useCanManage();
  return (
    <Drawer
      data-testid="mobile-nav-drawer"
      open={open}
      onClose={handleClose}
      anchor="left"
    >
      <Box sx={{ "& li a": { color: "primary.main" } }}>
        <Stack alignItems="flex-end" px={1} pt={1} mb={-4}>
          <IconButton size="small" onClick={handleClose} sx={{ zIndex: 100 }}>
            <KeyboardDoubleArrowLeft />
          </IconButton>
        </Stack>
        <MobileNavSearch />
        <MobileNavMenuItems />
        <Divider sx={{ my: 2 }} />
        <List disablePadding>
          <MobileMyLinksListItem />
        </List>
        <Divider sx={{ my: 2 }} />
        <MobileCommunityFeedback />
        {canManage && (
          <Box px={1}>
            <Button
              sx={{ width: "100%" }}
              href="/manage"
              variant="contained"
              LinkComponent={Link}
            >
              {t("manage")}
            </Button>
          </Box>
        )}
      </Box>
    </Drawer>
  );
}
