import { ReqPath } from "core/entities";
import { APIGet } from "./APIGet";

export function APIGetAll([reqPath, headers]: [
  reqPath: ReqPath,
  headers: HeadersInit,
]) {
  if (typeof reqPath === "string") {
    return APIGet([
      { path: reqPath, params: { maxResultCount: 1000 } },
      headers,
    ]);
  }
  const { path, params } = reqPath;
  return APIGet([
    { path, params: { maxResultCount: 1000, ...params } },
    headers,
  ]);
}
