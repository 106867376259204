import { ReactNode } from "react";
import { useRouter } from "next/router";
import { useExtensoToken } from "core/hooks/useExtensoToken";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { AvailableRoutes } from "resources/availableRoutes";

interface RedirectHandlerProps {
  children: ReactNode;
}

export function ExtensoTokenChecker({ children }: RedirectHandlerProps) {
  const { replace, pathname } = useRouter();
  const { token: extensoToken } = useExtensoToken();
  const custom401Pathname: AvailableRoutes = "/401";
  const tokenExpiredPathname: AvailableRoutes = "/token-expired";

  if (
    extensoToken &&
    pathname !== tokenExpiredPathname &&
    pathname !== custom401Pathname
  ) {
    try {
      const decodedToken = jwtDecode<JwtPayload>(extensoToken);
      if (!decodedToken || !decodedToken.exp) {
        replace(custom401Pathname);
        return null;
      }
      const expiredMilliseconds = decodedToken.exp * 1000;
      if (expiredMilliseconds < Date.now()) {
        replace(tokenExpiredPathname);
        return null;
      }
    } catch {
      replace(custom401Pathname);
      return null;
    }
  }

  return <>{children}</>;
}
