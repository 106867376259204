import { useUserPermissionContextsInCurrentBrandRegion } from "./useUserPermissionContextsInCurrentBrandRegion";

export function useDoesUserExistInCurrentContext() {
  const currentPermissionContexts =
    useUserPermissionContextsInCurrentBrandRegion();
  return (
    currentPermissionContexts &&
    Array.isArray(currentPermissionContexts) &&
    currentPermissionContexts.length > 0
  );
}
