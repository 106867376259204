import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SUPPORT_EMAIL } from "config";

export function ApplicationErrorBoundaryPage() {
  return (
    <Container maxWidth="lg" sx={{ height: "100vh" }}>
      <Stack height="100%" gap={16} alignItems="center" justifyContent="center">
        <Box>
          <Typography textAlign="center" variant="h3" marginBottom={2}>
            An error has occurred with this application
          </Typography>

          <Typography textAlign="center" variant="h5">
            The error has been logged. Please refresh the page, or contact{" "}
            <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
          </Typography>
        </Box>
        <Box>
          <Typography textAlign="center" variant="h3" marginBottom={2}>
            Une erreur s&apos;est produite avec cette application
          </Typography>

          <Typography textAlign="center" variant="h5">
            L&apos;erreur a été consignée. Veuillez actualiser la page ou
            contacter <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
          </Typography>
        </Box>
      </Stack>
    </Container>
  );
}
