import { useContext, useMemo } from "react";
import { CurrentBrandRegionContext } from "core/context/CurrentBrandRegionContext";
import type { PermissionContext } from "core/entities";
import { isSameContext } from "core/utils/isSameContext";
import { usePermissionContext } from "./usePermissionContext";

export function useUserPermissionContextsInCurrentBrandRegion():
  | PermissionContext[]
  | undefined {
  const currentBrandRegionContext = useContext(CurrentBrandRegionContext);

  const { data } = usePermissionContext();

  const userContextsInCurrentBrandRegion = useMemo(
    () =>
      Array.isArray(data)
        ? data?.filter((permContext) =>
            isSameContext(currentBrandRegionContext, permContext.context)
          )
        : undefined,
    [currentBrandRegionContext, data]
  );
  return userContextsInCurrentBrandRegion;
}
