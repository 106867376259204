import { BodyJSONData } from "core/entities";
import { APIFetch } from "core/utils/APIFetch";
import { validateResponse } from "core/utils/validateResponse";
import { useCallback } from "react";
import { useAPIHeaders } from "./useAPIHeaders";

export function useAPIPostJSON() {
  const headers = useAPIHeaders();
  return useCallback(
    async <T = any>(path: string, data: BodyJSONData): Promise<T> => {
      const res = await APIFetch(path, {
        method: "POST",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      await validateResponse(res);
      const jsonData = await res.json();
      return jsonData;
    },
    [headers]
  );
}
