import { APIGetAll } from "core/utils/APIGetAll";
import { APIError, ReqPath } from "core/entities";
import useSWR from "swr";
import { useAPIHeaders } from "./useAPIHeaders";

export function useAPIAll<T>(
  path: ReqPath | null,
  options?: Parameters<typeof useSWR<T, APIError>>[2]
) {
  const headers = useAPIHeaders();
  // https://swr.vercel.app/docs/conditional-fetching
  const key = path ? [path, headers] : null;
  const { data, error, mutate } = useSWR<T, APIError>(key, APIGetAll, options);
  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
