import { useQueryState, parseAsStringEnum } from "next-usequerystate";
import { DMSSortingOption } from "../entities";

export function useLibraryQueryStateSorting() {
  return useQueryState<DMSSortingOption>(
    "sorting",
    parseAsStringEnum([
      "name asc",
      "name desc",
      "creationTime asc",
      "creationTime desc",
    ]).withDefault("name asc")
  );
}
