// Using this recipe from MUI documentation for integrating the Nextjs Link Component with the Mui Link Component
// https://github.com/mui/material-ui/blob/3d8e3ea7c4b407968deccd494924f1a4b5d4bc61/examples/nextjs-with-typescript/src/Link.tsx

/* eslint-disable prefer-arrow-callback */
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import MuiLink, { LinkProps as MuiLinkProps } from "@mui/material/Link";
import clsx from "clsx";
import { styled } from "@mui/material/styles";
import React from "react";
import { useRouter } from "next/router";
import { useRegion } from "core/hooks/useRegion";

const Anchor = styled("a")({});

// updated to reflect changes https://github.com/mui/material-ui/blob/a0ccc0c603273d0874542bfa29875bb44c884f1f/examples/nextjs-with-typescript/src/Link.tsx
interface NextLinkComposedProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href">,
    Omit<
      NextLinkProps,
      "href" | "as" | "onClick" | "onMouseEnter" | "onTouchStart"
    > {
  to: NextLinkProps["href"];
  linkAs?: NextLinkProps["as"];
}

export const NextLinkComposed = React.forwardRef<
  HTMLAnchorElement,
  NextLinkComposedProps
>(function NextLinkComposed(props, ref) {
  const {
    to,
    linkAs,
    replace,
    scroll,
    shallow,
    prefetch,
    locale,
    passHref,
    ...other
  } = props;

  return (
    <NextLink
      href={to}
      prefetch={prefetch}
      as={linkAs}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref
      locale={locale}
      ref={ref}
      {...other}
    />
  );
});

export type LinkProps = {
  activeClassName?: string;
  as?: NextLinkProps["as"];
  href: NextLinkProps["href"];
  linkAs?: NextLinkProps["as"]; // Useful when the as prop is shallow by styled().
  noLinkStyle?: boolean;
  regionless?: boolean;
} & Omit<NextLinkComposedProps, "to" | "linkAs" | "href"> &
  Omit<MuiLinkProps, "href">;

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/api-reference/next/link
const LinkMuiNext = React.forwardRef<HTMLAnchorElement, LinkProps>(
  function LinkMuiNext(props, ref) {
    const {
      activeClassName = "active",
      as,
      className: classNameProps,
      href,
      linkAs: linkAsProp,
      locale,
      noLinkStyle,
      prefetch,
      replace,
      role, // Link don't have roles.
      scroll,
      shallow,
      passHref, // remove next link property
      ...other
    } = props;

    const router = useRouter();
    const pathname = typeof href === "string" ? href : href?.pathname;
    const className = clsx(classNameProps, {
      [activeClassName]: router.pathname === pathname && activeClassName,
    });

    const isExternal =
      typeof href === "string" &&
      (href.indexOf("http") === 0 || href.indexOf("mailto:") === 0);

    if (isExternal) {
      if (noLinkStyle) {
        return (
          <Anchor className={className} href={href} ref={ref} {...other} />
        );
      }

      return <MuiLink className={className} href={href} ref={ref} {...other} />;
    }

    const linkAs = linkAsProp || as;
    const nextjsProps = {
      to: href,
      linkAs,
      replace,
      scroll,
      shallow,
      prefetch,
      locale,
    };

    if (noLinkStyle) {
      return (
        <NextLinkComposed
          className={className}
          ref={ref}
          {...nextjsProps}
          {...other}
        />
      );
    }

    return (
      <MuiLink
        component={NextLinkComposed}
        className={className}
        ref={ref}
        {...nextjsProps}
        {...other}
      />
    );
  }
);

export const Link = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<LinkProps>
>((props, ref) => {
  const { href, children, passHref, regionless, underline, ...rest } = props;
  const region = useRegion();
  const { locale } = useRouter();
  const actualHref =
    href?.toString().startsWith("/") && !regionless
      ? `/${locale}${region ? `/${region}` : ""}${href}`
      : href;

  return (
    <LinkMuiNext
      {...rest}
      passHref={passHref}
      underline="none"
      href={actualHref}
      ref={ref}
    >
      {children}
    </LinkMuiNext>
  );
});
Link.displayName = "Link";
