import { MouseEventHandler } from "react";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export function ExpandButton({
  open,
  onClick,
  "data-testid": testId,
}: {
  "data-testid"?: string;
  open: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <IconButton
      data-testid={testId}
      sx={{
        transform: open ? "rotate(180deg)" : undefined,
        transition: "transform 200ms ease",
      }}
      onClick={onClick}
      edge="end"
    >
      <ExpandMoreIcon />
    </IconButton>
  );
}
