import useMediaQuery from "@mui/material/useMediaQuery";

/**
 * Gives information about the user's device.
 * Can be used to conditionally display UI elements
 */
export function useBreakpoints() {
  const isDesktop = useMediaQuery("(min-width: 1200px)");
  const isTablet = useMediaQuery("(min-width: 768px)");
  if (isDesktop) {
    return {
      isDesktop: true,
      isTablet: false,
      isMobile: false,
    };
  }
  if (isTablet) {
    return {
      isDesktop: false,
      isTablet: true,
      isMobile: false,
    };
  }
  // isMobile
  return {
    isDesktop: false,
    isTablet: false,
    isMobile: true,
  };
}
