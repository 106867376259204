import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { useRouter } from "next/router";
import { enCA, frCA } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { ReactNode } from "react";

export function DatePickerLocalizationProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { locale: routerLocale } = useRouter();
  const localeMap = {
    en: enCA,
    fr: frCA,
  };
  const providerLocale =
    localeMap[routerLocale as keyof typeof localeMap] || enCA;
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={providerLocale}
    >
      {children}
    </LocalizationProvider>
  );
}
