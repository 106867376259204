export const brandRegionConfig = {
  speedyglass: {
    brandCode: "speedyglass",
    regions: ["us"],
    regionInfos: [
      {
        regionCode: "us",
        marketCode: "speedyglassus",
        stateCodes: ["us-va"],
        subRegions: ["va"],
      },
    ],
    subRegions: ["va"],
    stateCodes: ["us-va"],
    marketCodes: ["speedyglassus"],
  },
  cre: {
    brandCode: "cre",
    regions: ["ca"],
    regionInfos: [
      {
        regionCode: "ca",
        marketCode: "creca",
        stateCodes: ["ca-ab", "ca-on", "ca-qc"],
        subRegions: ["ab", "on", "qc"],
      },
    ],
    subRegions: ["ab", "on", "qc"],
    stateCodes: ["ca-ab", "ca-on", "ca-qc"],
    marketCodes: ["creca"],
  },
  fixaut: {
    brandCode: "fixaut",
    regions: ["ca", "au", "mx"],
    regionInfos: [
      {
        regionCode: "mx",
        marketCode: "fixautmx",
        stateCodes: [],
        subRegions: [],
      },
      {
        regionCode: "au",
        marketCode: "fixautau",
        stateCodes: ["au-nsw", "au-qld", "au-vic", "au-wa"],
        subRegions: ["nsw", "qld", "vic", "wa"],
      },
      {
        regionCode: "ca",
        marketCode: "fixautca",
        stateCodes: [
          "ca-ab",
          "ca-bc",
          "ca-nb",
          "ca-nl",
          "ca-ns",
          "ca-on",
          "ca-pe",
          "ca-qc",
        ],
        subRegions: ["ab", "bc", "nb", "nl", "ns", "on", "pe", "qc"],
      },
    ],
    subRegions: [
      "nsw",
      "qld",
      "vic",
      "wa",
      "ab",
      "bc",
      "nb",
      "nl",
      "ns",
      "on",
      "pe",
      "qc",
    ],
    stateCodes: [
      "au-nsw",
      "au-qld",
      "au-vic",
      "au-wa",
      "ca-ab",
      "ca-bc",
      "ca-nb",
      "ca-nl",
      "ca-ns",
      "ca-on",
      "ca-pe",
      "ca-qc",
    ],
    marketCodes: ["fixautau", "fixautca", "fixautmx"],
  },
  novusglass: {
    brandCode: "novusglass",
    // regions: ["au", "ca", "us"],
    regions: ["ca"],
    regionInfos: [
      {
        regionCode: "au",
        marketCode: "novusglassau",
        stateCodes: ["au-act", "au-nsw", "au-qld", "au-sa", "au-tas", "au-vic"],
        subRegions: ["act", "nsw", "qld", "sa", "tas", "vic"],
      },
      {
        regionCode: "ca",
        marketCode: "novusglassca",
        stateCodes: [
          "ca-ab",
          "ca-bc",
          "ca-mb",
          "ca-nb",
          "ca-nl",
          "ca-ns",
          "ca-on",
          "ca-pe",
          "ca-qc",
          "ca-sk",
        ],
        subRegions: [
          "ab",
          "bc",
          "mb",
          "nb",
          "nl",
          "ns",
          "on",
          "pe",
          "qc",
          "sk",
        ],
      },
      {
        regionCode: "us",
        marketCode: "novusglassus",
        stateCodes: [
          "us-ak",
          "us-al",
          "us-ar",
          "us-az",
          "us-ca",
          "us-co",
          "us-ct",
          "us-fl",
          "us-ga",
          "us-ia",
          "us-id",
          "us-il",
          "us-in",
          "us-ks",
          "us-ky",
          "us-la",
          "us-md",
          "us-me",
          "us-mi",
          "us-mn",
          "us-mo",
          "us-ms",
          "us-mt",
          "us-nc",
          "us-ne",
          "us-nj",
          "us-nm",
          "us-nv",
          "us-ny",
          "us-oh",
          "us-ok",
          "us-or",
          "us-pa",
          "us-sc",
          "us-sd",
          "us-tn",
          "us-tx",
          "us-ut",
          "us-va",
          "us-wa",
          "us-wi",
          "us-wv",
          "us-wy",
        ],
        subRegions: [
          "ak",
          "al",
          "ar",
          "az",
          "ca",
          "co",
          "ct",
          "fl",
          "ga",
          "ia",
          "id",
          "il",
          "in",
          "ks",
          "ky",
          "la",
          "md",
          "me",
          "mi",
          "mn",
          "mo",
          "ms",
          "mt",
          "nc",
          "ne",
          "nj",
          "nm",
          "nv",
          "ny",
          "oh",
          "ok",
          "or",
          "pa",
          "sc",
          "sd",
          "tn",
          "tx",
          "ut",
          "va",
          "wa",
          "wi",
          "wv",
          "wy",
        ],
      },
    ],
    subRegions: [
      "act",
      "nsw",
      "qld",
      "sa",
      "tas",
      "vic",
      "ab",
      "bc",
      "mb",
      "nb",
      "nl",
      "ns",
      "on",
      "pe",
      "qc",
      "sk",
      "ak",
      "al",
      "ar",
      "az",
      "ca",
      "co",
      "ct",
      "fl",
      "ga",
      "ia",
      "id",
      "il",
      "in",
      "ks",
      "ky",
      "la",
      "md",
      "me",
      "mi",
      "mn",
      "mo",
      "ms",
      "mt",
      "nc",
      "ne",
      "nj",
      "nm",
      "nv",
      "ny",
      "oh",
      "ok",
      "or",
      "pa",
      "sc",
      "sd",
      "tn",
      "tx",
      "ut",
      "va",
      "wa",
      "wi",
      "wv",
      "wy",
    ],
    stateCodes: [
      "au-act",
      "au-nsw",
      "au-qld",
      "au-sa",
      "au-tas",
      "au-vic",
      "ca-ab",
      "ca-bc",
      "ca-mb",
      "ca-nb",
      "ca-nl",
      "ca-ns",
      "ca-on",
      "ca-pe",
      "ca-qc",
      "ca-sk",
      "us-ak",
      "us-al",
      "us-ar",
      "us-az",
      "us-ca",
      "us-co",
      "us-ct",
      "us-fl",
      "us-ga",
      "us-ia",
      "us-id",
      "us-il",
      "us-in",
      "us-ks",
      "us-ky",
      "us-la",
      "us-md",
      "us-me",
      "us-mi",
      "us-mn",
      "us-mo",
      "us-ms",
      "us-mt",
      "us-nc",
      "us-ne",
      "us-nj",
      "us-nm",
      "us-nv",
      "us-ny",
      "us-oh",
      "us-ok",
      "us-or",
      "us-pa",
      "us-sc",
      "us-sd",
      "us-tn",
      "us-tx",
      "us-ut",
      "us-va",
      "us-wa",
      "us-wi",
      "us-wv",
      "us-wy",
    ],
    marketCodes: ["novusglassau", "novusglassca", "novusglassus"],
  },
  proc: {
    brandCode: "proc",
    regions: ["ca", "us"],
    regionInfos: [
      {
        regionCode: "ca",
        marketCode: "procca",
        stateCodes: ["ca-ab", "ca-on", "ca-pe", "ca-qc"],
        subRegions: ["ab", "on", "pe", "qc"],
      },
      {
        regionCode: "us",
        marketCode: "procus",
        stateCodes: ["us-ca", "us-tx"],
        subRegions: ["ca", "tx"],
      },
    ],
    subRegions: ["ab", "on", "pe", "qc", "ca", "tx"],
    stateCodes: ["ca-ab", "ca-on", "ca-pe", "ca-qc", "us-ca", "us-tx"],
    marketCodes: ["procca", "procus"],
  },
  speedyauto: {
    brandCode: "speedyauto",
    regions: ["ca"],
    regionInfos: [
      {
        regionCode: "ca",
        marketCode: "speedyautoca",
        stateCodes: ["ca-ab", "ca-bc", "ca-nb", "ca-ns", "ca-on", "ca-qc"],
        subRegions: ["ab", "bc", "nb", "ns", "on", "qc"],
      },
    ],
    subRegions: ["ab", "bc", "nb", "ns", "on", "qc"],
    stateCodes: ["ca-ab", "ca-bc", "ca-nb", "ca-ns", "ca-on", "ca-qc"],
    marketCodes: ["speedyautoca"],
  },
} as const;

export const brandCodes = Object.values(brandRegionConfig).map(
  (entry) => entry.brandCode
);

export type BrandCode = (typeof brandCodes)[number];

export const regionCodes = ["ca", "us", "mx", "nz", "de", "au"] as const;

export type RegionCode = (typeof regionCodes)[number];

export const subRegionCodes = [
  ...new Set(
    Object.values(brandRegionConfig).flatMap((entry) => entry.subRegions)
  ),
];
export type SubRegionCode = (typeof subRegionCodes)[number];

export const brandRegionCodes = [
  ...new Set(
    Object.values(brandRegionConfig).flatMap((entry) => entry.marketCodes)
  ),
];
export type BrandRegionCode = (typeof brandRegionCodes)[number];

export const stateCodes = [
  ...new Set(
    Object.values(brandRegionConfig).flatMap((entry) => entry.stateCodes)
  ),
];
export type StateCode = (typeof stateCodes)[number];

export const brandRegionCodeRegionMap: Record<BrandRegionCode, RegionCode> = {
  speedyglassus: "us",
  creca: "ca",
  fixautau: "au",
  fixautca: "ca",
  fixautmx: "mx",
  novusglassau: "au",
  novusglassca: "ca",
  novusglassus: "us",
  procca: "ca",
  procus: "us",
  speedyautoca: "ca",
} as const;
