import { useCallback } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { IExceptionTelemetry } from "@microsoft/applicationinsights-web";

export function useTrackException() {
  const appInsights = useAppInsightsContext();
  const trackException = useCallback(
    (telemetry: IExceptionTelemetry) => {
      appInsights.trackException(telemetry);
    },
    [appInsights]
  );
  return trackException;
}
