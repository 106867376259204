import { useRouter } from "next/router";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  IDocTranslationDto,
  TranslationKey,
  WithSurfacedTranslation,
} from "../entities";

export function useContainerProcessor() {
  const { locale } = useRouter();
  const { t } = useTranslation("DocumentManagementSystem");
  const processContainerCallback = useCallback(
    <T extends { translations: IDocTranslationDto[] }>(
      container: T
    ): WithSurfacedTranslation<T> & { languages: string } => {
      const { translations } = container;
      const languages =
        translations
          ?.map(({ transKey }) => t(`Next:Core:LanguageCodes.${transKey}`))
          .join(" | ") ?? "";
      const currentLocaleTranslation = translations?.find(
        (translation) => translation?.transKey === locale
      );
      if (currentLocaleTranslation) {
        const { name, description, tags, transKey } = currentLocaleTranslation;
        const currentTranslationLanguage = t(
          `Next:Core:LanguageCodes.${transKey}`
        );
        return {
          ...container,
          name,
          description,
          tags,
          transKey,
          languages,
          isTranslationAvailableInCurrentLocale: true,
          currentTranslationLanguage,
        };
      }
      const {
        name = "",
        description,
        tags,
        transKey = TranslationKey.En as string,
      } = translations?.[0] || {};
      const currentTranslationLanguage = t(
        `Next:Core:LanguageCodes.${transKey}`
      );

      return {
        ...container,
        name,
        description,
        tags,
        transKey,
        languages,
        isTranslationAvailableInCurrentLocale: false,
        currentTranslationLanguage,
      };
    },
    [t, locale]
  );
  return processContainerCallback;
}
