import { ReactNode } from "react";
import WebIcon from "@mui/icons-material/Web";
import OpenInNew from "@mui/icons-material/OpenInNew";
import ArticleIcon from "@mui/icons-material/Article";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import AssessmentIcon from "@mui/icons-material/Assessment";
import TodayIcon from "@mui/icons-material/Today";
import TopicIcon from "@mui/icons-material/Topic";
import { ItemType } from "core/entities";

const IconMap: Record<ItemType, ReactNode> = {
  Page: <WebIcon color="inherit" />,
  DmsDocument: <ArticleIcon color="inherit" />,
  DmsLibrary: <TopicIcon color="inherit" />,
  Event: <TodayIcon color="inherit" />,
  News: <NewspaperIcon color="inherit" />,
  External: <OpenInNew color="inherit" />,
  Extenso: <AssessmentIcon color="inherit" />,
  CMSKitPage: <WebIcon color="inherit" />,
  CMSKitBlogPost: <WebIcon color="inherit" />,
};

export function MyLinksIcon({ itemType }: { itemType: ItemType }) {
  return <>{IconMap[itemType]}</>;
}
