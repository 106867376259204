import { useContext, useMemo } from "react";
import { CurrentBrandRegionContext } from "core/context/CurrentBrandRegionContext";
import { extractValueFromContext } from "core/utils/extractValueFromContext";
import { useToken } from "./useToken";

export function useAPIHeaders() {
  const urlContext = useContext(CurrentBrandRegionContext);
  const token = useToken();

  const brand = extractValueFromContext(urlContext, "brand");
  const region = extractValueFromContext(urlContext, "region");
  const headers = useMemo(() => {
    const initialHeaders: HeadersInit = {
      "x-fixnetwork-brand": brand ?? "",
      "x-fixnetwork-region": region ?? "",
    };

    if (token) {
      initialHeaders.Authorization = `Bearer ${token}`;
    }
    return initialHeaders;
  }, [brand, region, token]);

  return headers;
}
