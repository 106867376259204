import React, { ReactNode, useMemo, useState } from "react";

type ExtensoTokenState = {
  token?: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
};

export const ExtensoToken = React.createContext<ExtensoTokenState>({
  token: null,
  setToken: () => {},
});

interface ExtensoTokenProviderProps {
  children: ReactNode;
}

export default function ExtensoTokenProvider({
  children,
}: ExtensoTokenProviderProps) {
  const [token, setToken] = useState<string | null>(null);
  const contextValue = useMemo(() => ({ token, setToken }), [token]);
  return (
    <ExtensoToken.Provider value={contextValue}>
      {children}
    </ExtensoToken.Provider>
  );
}
