import { BrandCode } from "core/entities";

const DomainMap = new Map<string, BrandCode>([
  ["procolor", "proc"],
  ["fixauto", "fixaut"],
  ["novusglass", "novusglass"],
  ["speedyauto", "speedyauto"],
  ["speedy", "speedyauto"],
  ["speedyglass", "speedyglass"],
  ["collisionrepairexperts", "cre"],
]);

export default DomainMap;
