import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationIcon from "@mui/icons-material/Notifications";
import { useNotifications } from "./useNotifications";
import { NotificationsMenu } from "./NotificationsMenu";
import { useNotificationRouteWatcher } from "./useNotificationRouteWatcher";

export function HeaderNotifications() {
  useNotificationRouteWatcher();
  const { totalUnseenCount, markAllNotificationsAsSeen } = useNotifications();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  async function closeMenu() {
    setAnchorEl(null);
    try {
      await markAllNotificationsAsSeen();
    } catch {
      // ignore
    }
  }
  return (
    <>
      <IconButton
        data-testid="notifications-menu-button"
        sx={{ color: "primary.contrastText" }}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <Badge badgeContent={totalUnseenCount} max={9} color="secondary">
          <NotificationIcon color="inherit" />
        </Badge>
      </IconButton>
      <NotificationsMenu
        anchorEl={anchorEl}
        isOpen={Boolean(anchorEl)}
        onClose={closeMenu}
      />
    </>
  );
}
