import { Component, ErrorInfo, ReactNode } from "react";
import Box, { BoxProps } from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  ReactPlugin,
  AppInsightsContext,
} from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useTranslation } from "react-i18next";

interface FallBackUIProps extends BoxProps {
  reset: () => void;
}

function FallBackUI({ reset, ...rest }: FallBackUIProps) {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:ComponentErrorBoundary",
  });
  return (
    <Box
      {...rest}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        width: "100%",
        height: "100%",
        ...rest.sx,
      }}
    >
      <Typography variant="h5" color="error.main">
        {t("errorMessage")}
      </Typography>
      <Button onClick={reset} variant="outlined">
        {t("button")}
      </Button>
    </Box>
  );
}

// https://reactjs.org/docs/error-boundaries.html
export class ComponentErrorBoundary extends Component<
  { children?: ReactNode; fallBackBoxProps?: BoxProps },
  { hasError: boolean }
> {
  constructor(props: any) {
    super(props);
    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const appInsights = this.context as ReactPlugin;
    appInsights.trackException({
      error,
      exception: error,
      severityLevel: SeverityLevel.Error,
      properties: errorInfo,
    });
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children, fallBackBoxProps } = this.props;
    // Check if the error is thrown
    if (hasError) {
      return (
        <FallBackUI
          {...fallBackBoxProps}
          reset={() => this.setState({ hasError: false })}
        />
      );
    }

    return children;
  }
}

ComponentErrorBoundary.contextType = AppInsightsContext;
