import { useRef, useEffect, MutableRefObject } from "react";
import { useRouter } from "next/router";

export function useScrollTopOnNavigation(): MutableRefObject<HTMLDivElement | null> {
  const containerRef = useRef<null | HTMLDivElement>(null);
  const { asPath } = useRouter();
  const pathNoParams = asPath.split("?")[0];
  useEffect(() => {
    if (containerRef.current !== null) {
      containerRef.current.scroll(0, 0);
    }
  }, [pathNoParams]);
  return containerRef;
}
