import DotIcon from "@mui/icons-material/FiberManualRecord";
import Fade from "@mui/material/Fade";
import { useNotifications } from "features/Notifications/useNotifications";

export function NotificationDot({
  id,
  mode,
  translate = { x: 0, y: 2 },
}: {
  id: string;
  mode: "parent" | "self" | "either";
  translate?: { x: number; y: number };
}) {
  const {
    hasUnreadNotification,
    shouldEntityHaveNotificationDotById,
    hasUnreadNotificationChildren,
  } = useNotifications();
  let shouldShowDot = false;
  if (mode === "parent") {
    shouldShowDot = hasUnreadNotificationChildren(id);
  } else if (mode === "self") {
    shouldShowDot = hasUnreadNotification(id);
  } else if (mode === "either") {
    shouldShowDot = shouldEntityHaveNotificationDotById(id);
  }
  return (
    <Fade in={shouldShowDot}>
      <DotIcon
        sx={{ translate: `${translate.x}px ${translate.y}px` }}
        color="secondary"
        fontSize="inherit"
      />
    </Fade>
  );
}
