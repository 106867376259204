import Container, { ContainerProps } from "@mui/material/Container";
import { useExtensoToken } from "core/hooks/useExtensoToken";
import { ElementType } from "react";

export function PageContainer({
  maxWidth,
  ...rest
}: ContainerProps & { component?: ElementType<any> }) {
  const { token: isExtenso } = useExtensoToken();
  return <Container maxWidth={isExtenso ? false : maxWidth} {...rest} />;
}
