import { APIGet } from "core/utils/APIGet";
import { ReqPath, APIError } from "core/entities";
import useSWR from "swr";
import { useAPIHeaders } from "./useAPIHeaders";

export function useAPI<T>(
  path: ReqPath | null,
  options?: Parameters<typeof useSWR<T, APIError>>[2]
) {
  const headers = useAPIHeaders();
  // https://swr.vercel.app/docs/conditional-fetching
  const key = path ? [path, headers] : null;
  const { data, error, mutate } = useSWR<T, APIError>(key, APIGet, options);
  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
