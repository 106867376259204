import type { RegionCode } from "core/entities";
import { ReactNode, createContext } from "react";

export const AvailableRegionsContext = createContext<RegionCode[]>([]);
export function AvailableRegionsContextProvider({
  children,
  availableRegions,
}: {
  availableRegions: RegionCode[];
  children: ReactNode;
}) {
  return (
    <AvailableRegionsContext.Provider value={availableRegions}>
      {children}
    </AvailableRegionsContext.Provider>
  );
}
