export function uniqueKeyGetter(): (input: string | number) => string {
  const uniqueKeysSet = new Set();

  function getKey(inputKey: string | number, iteration = 1): string {
    const inputAsString =
      typeof inputKey === "number" ? inputKey.toString() : inputKey;
    if (!uniqueKeysSet.has(inputAsString)) {
      uniqueKeysSet.add(inputAsString);
      return inputAsString;
    }
    const inputWithSuffix = `${inputAsString}-${iteration}`;
    if (!uniqueKeysSet.has(inputWithSuffix)) {
      uniqueKeysSet.add(inputWithSuffix);
      return inputWithSuffix;
    }
    const newIteration = iteration + 1;
    return getKey(inputAsString, newIteration);
  }
  return getKey;
}
