import { ReqPath } from "core/entities";
import { useCallback } from "react";
import { useAPIFetch } from "./useAPIFetch";

export function useAPIDelete() {
  const apiFetch = useAPIFetch();
  return useCallback(
    (reqPath: ReqPath) => apiFetch(reqPath, { method: "DELETE" }),
    [apiFetch]
  );
}
