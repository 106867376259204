import Head from "next/head";
import { ReactNode } from "react";
import Stack, { StackProps } from "@mui/material/Stack";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";
// import { UserGuideLink } from "./UserGuideLink";

export interface PageHeaderProps extends BoxProps {
  title: string;
  color?: TypographyProps["color"];
  titleTypographyProps?: TypographyProps<"h1", {}>;
  subTitle?: string | string[] | ReactNode;
  subTitleTypographyProps?: TypographyProps;
  TopSlot?: ReactNode;
  RightSlot?: ReactNode;
  rightSlotProps?: BoxProps;
  TitleSlot?: ReactNode;
  titleStackProps?: StackProps;
  userGuideHref?: string | undefined;
  updateHeadTitle?: boolean;
}

export function PageHeader({
  title,
  titleTypographyProps,
  subTitle,
  color,
  subTitleTypographyProps,
  TopSlot,
  RightSlot,
  TitleSlot,
  titleStackProps,
  userGuideHref,
  rightSlotProps,
  updateHeadTitle = true,
  ...props
}: PageHeaderProps) {
  return (
    <>
      {updateHeadTitle && (
        <Head>
          <title>{title}</title>
        </Head>
      )}
      {TopSlot}
      <Box mb={3} {...props}>
        <Stack
          direction="row"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="flex-start"
          mb={2}
          {...titleStackProps}
        >
          <Stack
            direction="row"
            columnGap={3}
            alignItems="center"
            flexWrap="wrap"
          >
            <Typography
              variant="h3"
              component="h1"
              color={color || "text.primary"}
              {...titleTypographyProps}
            >
              {title}
              {/* {userGuideHref && (
                <UserGuideLink
                  sx={{ translate: "-2px -10px" }}
                  href={userGuideHref}
                />
              )} */}
            </Typography>
            {TitleSlot}
          </Stack>
          <Box alignSelf="center" {...rightSlotProps}>
            {RightSlot}
          </Box>
        </Stack>
        {typeof subTitle === "string" ? (
          <Typography
            maxWidth="65ch"
            variant="subtitle1"
            color={color || "text.secondary"}
            {...subTitleTypographyProps}
          >
            {subTitle}
          </Typography>
        ) : (
          subTitle
        )}
      </Box>
    </>
  );
}
