import { useCallback, useState } from "react";
import { MenuActions } from "../entities";
import { ActionModalProps } from "../components/ActionModalProps";

export function useMenuActions() {
  const [action, setAction] = useState<MenuActions | null>(null);
  const [id, setId] = useState<string | null>(null);
  const [parentId, setParentId] = useState<string | null>(null);
  const [docLibraryId, setDocLibraryId] = useState<string | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const closeModal = useCallback(() => {
    setAction(null);
    setId(null);
    setParentId(null);
    setDocLibraryId(null);
  }, [setAction, setId, setParentId, setDocLibraryId]);

  function getHandleClose() {
    // specialActions bypass opening a modal
    // currently using this to either navigate to and external link, or download a document
    return () => {
      setMenuAnchorEl(null);
    };
  }

  function closeMenu() {
    setMenuAnchorEl(null);
  }

  const openModalAction = useCallback(
    ({
      id: currentId,
      parentId: currentParentId,
      docLibraryId: currentDocLibraryId,
      action: currentAction,
    }: {
      id?: string;
      parentId?: string;
      docLibraryId?: string;
      action: MenuActions;
    }) => {
      setId(currentId ?? null);
      setParentId(currentParentId ?? null);
      setDocLibraryId(currentDocLibraryId ?? null);
      setAction(currentAction);
    },
    [setId, setParentId, setDocLibraryId, setAction]
  );

  const isModalOpen = !!action;
  const actionModalProps: ActionModalProps = {
    id,
    parentId,
    docLibraryId,
    closeModal,
    action,
    isModalOpen,
  };

  return {
    action,
    setAction,
    openModalAction,
    isModalOpen,
    closeModal,
    menuAnchorEl,
    getHandleClose,
    closeMenu,
    ActionModalProps: actionModalProps,
  };
}
