import SubDomainMap from "resources/SubDomainMap";
import DomainMap from "resources/DomainMap";
import { BrandCode } from "core/entities";

export function getBrandCodeFromHost(host: string): BrandCode | undefined {
  const parts = host.split(".");
  const [subdomain, domain] = parts;
  const domainBrandCode = domain && DomainMap.get(domain);
  if (domainBrandCode) {
    return domainBrandCode;
  }
  const subdomainBrandCode = subdomain && SubDomainMap.get(subdomain);
  if (subdomainBrandCode) {
    return subdomainBrandCode;
  }
  return undefined;
}
