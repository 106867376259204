import { useRouter } from "next/router";
import { useLibraryById } from "./useLibraryById";

/**
 * Use to identify if the page is in edit mode based on the "edit" query string parameter and whether the "canManage" property of the parent Library is true
 * @param libraryId Id of the parent Library
 * @returns boolean
 */
export function useIsEditMode(libraryId: string) {
  const {
    query: { edit: queryEdit },
  } = useRouter();
  const edit = String(queryEdit);
  const { data } = useLibraryById(libraryId);
  return edit === "true" && !!data?.canManage;
}
