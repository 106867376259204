import { ReqPath } from "core/entities";
import { APIFetch } from "./APIFetch";
import { buildQueryString } from "./buildQueryString";
import { validateResponse } from "./validateResponse";

export async function APIGet<T = any>([reqPath, headers]: [
  reqPath: ReqPath,
  headers: HeadersInit,
]): Promise<T> {
  if (typeof reqPath === "string") {
    const res = await APIFetch(reqPath, { headers });
    await validateResponse(res);
    return res.json();
  }
  const { path, params } = reqPath;
  const queryString = buildQueryString(params);
  const fullPath = queryString ? `${path}?${queryString}` : path;
  const res = await APIFetch(fullPath, { headers });
  await validateResponse(res);

  return res.json();
}
