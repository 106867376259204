import { useCallback } from "react";
import { useRouter } from "next/router";
import {
  IDocDocumentDto,
  TranslationKey,
  WithSurfacedAttachment,
  WithSurfacedTranslation,
} from "../entities";
import { useCurrentTranslationExtractor } from "./useCurrentTranslationExtractor";
import { extractDefaultTranslationKey, extractAttachment } from "../utils";

export function useDocumentProcessor() {
  const { locale } = useRouter();
  const translationExtractor = useCurrentTranslationExtractor();
  const processDocument = useCallback(
    (
      document: IDocDocumentDto
    ): WithSurfacedAttachment<WithSurfacedTranslation<IDocDocumentDto>> => {
      const { translations = [], attachments = [] } = document;

      // extract default translationKey from translations. undefined if
      const defaultTransKey = extractDefaultTranslationKey(translations);
      const {
        description,
        name = "",
        transKey = "",
        tags,
      } = translationExtractor(translations) || {};

      const { fileId, fileName, documentLink, thumbnailId, thumbnailName } =
        extractAttachment(
          attachments,
          locale as TranslationKey,
          defaultTransKey
        ) || {};
      return {
        ...document,
        description,
        transKey,
        name,
        tags,
        fileId,
        fileName,
        thumbnailId,
        thumbnailName,
        documentLink,
      };
    },
    [locale, translationExtractor]
  );
  return processDocument;
}
