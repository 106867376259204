import { useRef } from "react";
import { SubmitErrorHandler, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getFieldNameErrorObjectMap } from "core/utils/getFieldNameErrorObjectMap";
import { useValidationErrorToaster } from "core/hooks/useValidationErrorToaster";
import { DMSFormType } from "../entities";

export function useDMSFormErrorHandler(
  formMethods: UseFormReturn<any>,
  formType: DMSFormType
) {
  const scrollToRef = useRef<HTMLElement>(null);

  const errorToaster = useValidationErrorToaster();
  const { t } = useTranslation("DocumentManagementSystem");

  const errorHandler: SubmitErrorHandler<any> = (errors) => {
    const errorMap = getFieldNameErrorObjectMap(errors);
    const errorTArgs = Object.keys(errorMap).map((key) => {
      // process translations and attachments fields separately
      if (/description|name|fileId|documentLink/gi.test(key)) {
        const { 1: locale, 2: fieldName } = key.split(".");
        return t(`Next:DocumentManagementSystem:${formType}.${fieldName}`, {
          locale,
        });
      }
      return t(`Next:DocumentManagementSystem:${formType}.${key}`);
    });
    errorToaster(errorTArgs);

    // hacky solution to scroll smoothly after the rerender
    setTimeout(() => {
      scrollToRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  return { errorHandler, scrollToRef };
}
