import { usePermissionChecker } from "core/hooks/usePermissionChecker";

export function useHasLibraryCRUD() {
  const { hasSomePermissions } = usePermissionChecker();
  const hasLibraryCrud = hasSomePermissions([
    "Fixhub.DocLibraries.Create",
    "Fixhub.DocLibraries.Edit",
    "Fixhub.DocLibraries.Delete",
  ]);
  return hasLibraryCrud;
}
