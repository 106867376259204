import { useRouter } from "next/router";
import { useCallback } from "react";
import { TranslationKey } from "../entities";
import { extractCurrentTranslation } from "../utils/extractCurrentTranslation";

export function useCurrentTranslationExtractor() {
  const { locale } = useRouter();
  const currentTranslationExtractor = useCallback(
    <T extends { transKey: TranslationKey }>(translations: T[]) =>
      extractCurrentTranslation(translations, locale as TranslationKey),
    [locale]
  );
  return currentTranslationExtractor;
}
