import { HTMLAttributeAnchorTarget, ReactNode } from "react";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import MarkReadIcon from "@mui/icons-material/Drafts";
import MarkUnreadIcon from "@mui/icons-material/Email";
import IconButton from "@mui/material/IconButton";
import ListItem, { ListItemProps } from "@mui/material/ListItem";
import { useBreakpoints } from "core/hooks/useBreakpoints";
import ListItemText from "@mui/material/ListItemText";
import { useNotifications } from "../useNotifications";

type NotificationListItemTemplateProps = {
  id: string;
  isRead: boolean;
  AvatarIcon: ReactNode;
  listItemButtonProps?: {
    LinkComponent?: any;
    href: string;
    target?: HTMLAttributeAnchorTarget;
  };
  text: string;
  closeMenu: () => Promise<void>;
  listItemProps?: ListItemProps;
};

export function NotificationListItemTemplate({
  id,
  isRead,
  AvatarIcon,
  listItemButtonProps,
  text,
  closeMenu,
  listItemProps,
}: NotificationListItemTemplateProps) {
  const {
    deleteNotification,
    markNotificationAsRead,
    markNotificationAsUnread,
    markAllNotificationsAsSeen,
  } = useNotifications();
  const { isDesktop } = useBreakpoints();
  return (
    <ListItem
      data-testid="notifications-menu-item"
      disablePadding
      {...listItemProps}
      sx={{
        // borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        transition: "background-color 0.2s",
        backgroundColor: isRead ? "transparent" : "primary.lightest",
        "&& .MuiListItemButton-root": {
          paddingRight: "72px",
        },
        "& .MuiListItemSecondaryAction-root": {
          visibility: isDesktop ? "hidden" : "visible",
          opacity: isDesktop ? 0 : 1,
          translate: 12,
        },
        "&:hover .MuiListItemSecondaryAction-root": {
          visibility: "visible",
          opacity: 1,
        },
        ...listItemProps?.sx,
      }}
      secondaryAction={
        <Stack direction="row">
          {!isRead ? (
            <IconButton
              size="small"
              onClick={async () => {
                await markNotificationAsRead(id);
              }}
            >
              <MarkReadIcon />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              onClick={async () => {
                await markNotificationAsUnread(id);
              }}
            >
              <MarkUnreadIcon />
            </IconButton>
          )}

          <IconButton
            size="small"
            onClick={async () => {
              await deleteNotification(id);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      }
    >
      <ListItemButton
        onClick={async () => {
          await markNotificationAsRead(id);
          await markAllNotificationsAsSeen();
          closeMenu();
        }}
        {...listItemButtonProps}
      >
        <ListItemAvatar>
          <Avatar>{AvatarIcon}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={text}
          primaryTypographyProps={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        />
      </ListItemButton>
    </ListItem>
  );
}
