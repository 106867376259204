import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { UrlObject } from "url";
import { ParsedUrlQuery } from "querystring";
import Link from "next/link";

export function AvailableRegionMenuItem({
  selected,
  region,
  pathname,
  query,
  locale,
  onClick,
  label,
}: {
  label: string;
  selected: boolean;
  region: string;
  pathname: string;
  query: ParsedUrlQuery;
  locale: string | undefined;
  onClick?:
    | (React.MouseEventHandler<HTMLAnchorElement> &
        React.MouseEventHandler<HTMLSpanElement>)
    | undefined;
}) {
  const href: UrlObject = {
    pathname: `/${locale}${pathname}`,
    query: { ...query, region },
  };
  return (
    <Link href={href} onClick={onClick}>
      <MenuItem sx={{ color: "text.primary" }} selected={selected}>
        {label}
      </MenuItem>
    </Link>
  );
}
