import { BrandCode } from "core/entities";

const SubDomainMap = new Map<string, BrandCode>([
  ["portal-cre", "cre"],
  ["portal-fixauto", "fixaut"],
  ["portal-novusglass", "novusglass"],
  ["portal-procolor", "proc"],
  ["portal-speedy", "speedyauto"],
  ["portal-speedyauto", "speedyauto"],
  ["portal-speedyglass", "speedyglass"],
  ["portal-collisionrepairexperts", "cre"],
]);

export default SubDomainMap;
