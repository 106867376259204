import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { ReactNode } from "react";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import { useBreakpoints } from "core/hooks/useBreakpoints";
import Box from "@mui/material/Box";
import { UserMenu } from "../UserMenu";
import { BrandLogo } from "../BrandLogo";

function UnauthorizedLayoutHeader() {
  const { isDesktop } = useBreakpoints();
  return (
    <Box
      component="header"
      sx={{ flexGrow: 1, boxShadow: (theme) => theme.shadows[1], zIndex: 1 }}
    >
      <AppBar position="static" component="section">
        <Toolbar
          disableGutters
          sx={{ px: 3, justifyContent: "space-between", gap: 3 }}
        >
          <Box pt={1} maxWidth={175}>
            {!isDesktop && <BrandLogo />}
          </Box>
          <UserMenu menuItems={["logout"]} />
        </Toolbar>
      </AppBar>
      {isDesktop && (
        <Stack
          component="nav"
          direction="row"
          alignItems="center"
          px={6}
          spacing={3}
          bgcolor="background.paper"
          maxWidth="100vw"
        >
          <Box width={200} py={1}>
            <BrandLogo />
          </Box>
        </Stack>
      )}
    </Box>
  );
}

export function UnauthorizedLayout({ children }: { children: ReactNode }) {
  return (
    <Box display="grid" height="100vh" gridTemplateRows="auto 1fr">
      <UnauthorizedLayoutHeader />
      <Stack overflow="auto" height="100%" bgcolor="background.default">
        <Container maxWidth="md" sx={{ flex: 1, py: 2 }}>
          {children}
        </Container>
      </Stack>
    </Box>
  );
}
